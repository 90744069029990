@import 'colors';

// global styles
@import 'typography';
@import 'buttons';
@import 'ui';

* {
  box-sizing: border-box;
  user-select: none;
}

body, html {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: map-get($color-neutral, 100);
  font-family: PrimaryFont, sans-serif;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: rgba(var(--color-neutral-100-rgb), 0.1);
}

::-webkit-scrollbar-corner {
    background: rgba(var(--color-neutral-100-rgb), 0.1);
}

/* For Webkit browsers like Chrome, Safari */

::-webkit-scrollbar-thumb {
  background: rgba(var(--color-neutral-100-rgb), 0.25);
  border-radius: 10px;
  height: 10px;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: auto;
    scrollbar-color: rgba(var(--color-neutral-100-rgb), 0.1) rgba(var(--color-neutral-100-rgb), 0.25);
  }
}
