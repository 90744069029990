@import 'colors';

.custom-scroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(var(--color-neutral-100-rgb), 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--color-neutral-200-rgb), 0.25);
    border-radius: 10px;
  }
}

.invisible-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.uppercase {
  text-transform: uppercase;
}

.cursor {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.hidden {
  visibility: hidden;
}


// Layout styles
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

