@import 'colors';
@import 'sizes';

.action-button {
  @extend .text-primary-font;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: none;
  background: map-get($color-accent, 100);
  font-weight: map-get($font-weights, 700);
  line-height: 1em;
  letter-spacing: 1px;
  color: var(--z-action-button-font-color, #{map-get($color-neutral, 100)});
  transition: transform 0.1s;
  cursor: pointer;

  &.maxi {
    width: 320px;
    height: 70px;
    font-size: map-get($font-sizes, 500);
  }

  &.big {
    width: 240px;
    height: 60px;
    font-size: map-get($font-sizes, 450);
  }

  &.med {
    width: 200px;
    height: 50px;
    font-size: map-get($font-sizes, 300);
  }

  &.small {
    width: 160px;
    height: 40px;
    font-size: map-get($font-sizes, 300);
  }

  &.micro {
    width: 120px;
    height: 40px;
    font-size: map-get($font-sizes, 200);
  }

  &.outline {
    position: relative;
    background: transparent;
    color: var(
      --z-action-secondary-button-font-color,
      map-get($color-neutral, 100)
    );
  }

  &.outline::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 2px;
    border-radius: 30px;
    background: var(
      --color-accent-1,
      linear-gradient(180deg, #ffe501 0%, #ea5f01 100%)
    );
    mask:
      linear-gradient(#ffffff 0px, #ffffff 0px) content-box exclude,
      linear-gradient(#ffffff 0px, #ffffff 0px);
    -webkit-mask:
      linear-gradient(#ffffff 0px, #ffffff 0px) content-box exclude,
      linear-gradient(#ffffff 0px, #ffffff 0px);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:active {
    opacity: 0.9;
    transform: translateY(2px);
  }
}

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  img {
    width: 24px;
  }
}
