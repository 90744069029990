@import 'sizes';
@import 'colors';

// desktop
.text-extra-large {
  font-size: map-get($font-sizes, 750);
  font-weight: map-get($font-weights, 700);
  line-height: map-get($line-heights, 110);
}

.text-large {
  font-size: map-get($font-sizes, 550);
  font-weight: map-get($font-weights, 700);
  line-height: map-get($line-heights, 200);
}

.text-big {
  font-size: map-get($font-sizes, 500);
  font-weight: map-get($font-weights, 700);
  line-height: map-get($line-heights, 200);
}

.text-medium {
  font-size: map-get($font-sizes, 450);
  font-weight: map-get($font-weights, 500);
  line-height: map-get($line-heights, 500);
}

.text-small {
  font-size: map-get($font-sizes, 250);
  font-weight: map-get($font-weights, 700);
  line-height: map-get($line-heights, 200);
}

.text-body1 {
  font-size: map-get($font-sizes, 250);
  font-weight: map-get($font-weights, 400);
  line-height: map-get($line-heights, 400);
}

.text-body1-bold {
  font-size: map-get($font-sizes, 250);
  font-weight: map-get($font-weights, 600);
  line-height: map-get($line-heights, 400);
}

.text-body2 {
  font-size: map-get($font-sizes, 200);
  font-weight: map-get($font-weights, 400);
  line-height: map-get($line-heights, 400);
}

.text-body2-bold {
  font-size: map-get($font-sizes, 200);
  font-weight: map-get($font-weights, 600);
  line-height: map-get($line-heights, 400);
}

.text-microcopy {
  font-size: map-get($font-sizes, 150);
  font-weight: map-get($font-weights, 700);
  line-height: map-get($line-heights, 200);
  text-transform: uppercase;
}

// mobile
.mobile-version {
  .text-extra-large {
    font-size: map-get($font-sizes, 520);
  }

  .text-large {
    font-size: map-get($font-sizes, 400);
  }

  .text-big {
    font-size: map-get($font-sizes, 400);
  }

  .text-medium {
    font-size: map-get($font-sizes, 300);
  }

  .text-small {
    font-size: map-get($font-sizes, 200);
  }

  .text-body1 {
    font-size: map-get($font-sizes, 200);
  }

  .text-body1-bold {
    font-size: map-get($font-sizes, 200);
  }

  .text-body2 {
    font-size: map-get($font-sizes, 150);
  }

  .text-body2-bold {
    font-size: map-get($font-sizes, 150);
  }

  .text-microcopy {
    font-size: map-get($font-sizes, 100);
  }
}

.text-primary-font {
  font-family: PrimaryFont, sans-serif;
}
